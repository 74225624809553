.banner {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16/9;
    // min-height: 350px;
    // height: 100vh;
}

.banner-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    // min-width: 100%;
    // min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.content {
    position: relative;
    z-index: 10;
    /* Ensure content is above the video */
    /* Add any additional styling for your content */
}

.banner .overlay {
    z-index: 5;
    background-color: black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .banner {
        position: relative;
        overflow: hidden;
        aspect-ratio: 16/9;
        // min-height: 350px;
        // height: 100vh;
    }
    
    .banner-video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        // min-width: 100%;
        // min-height: 100%;
        transform: translate(-50%, -50%);
        object-fit: contain;
    }
}