/* 04.8 Icon Box CSS */

.icon-box {
    position: relative;
    display: block;

    padding: 50px 60px;

    transition: var(--transition);
    border-radius: 5px;
    z-index: 99;
    background-color: var(--clr-white);
    &.box-border{
        border: none;
        // &:hover{
        //     border-color:  var(--clr-white);
        //     box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        // }
    }
    // Responsive
    @media #{$desktop-device}{
        padding: 30px 35px;
    }
    @media #{$tablet-device}{
        padding: 30px 35px;
    }
    @media #{$large-mobile}{
        padding: 20px 25px;
    }

    & .icon {
        margin-bottom: 35px;
        @media #{$desktop-device}{
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            margin-bottom: 20px;
        }

        & svg {
            width: 50px;
            height: 50px;
            & path{
                stroke: var(--clr-primary);
            }
        }
    }

    & .content {
        & .title {
            font-size: 20px;
            line-height: 1;

            margin: 0;

            color: var(--clr-heading);
        }

        & .desc {
            margin-top: 25px;
            // Responsive
            @media #{$desktop-device}{
                margin-top: 20px;
            }
            @media #{$large-mobile}{
                margin-top: 20px;
            }
            & p {
                line-height: 1.6;

                color: var(--clr-body);
            }
        }

        & .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            display: inline-flex;
            align-items: center;
            margin-top: 35px;
            color: var(--clr-primary);
            // Responsive
            @media #{$desktop-device}{
                margin-top: 20px;
            }
            @media #{$large-mobile}{
                margin-top: 20px;
            }
        }
    }

    // &:hover {
    //     box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
    //     transform: translateY(-5px);
    //     z-index: 1;

        
        

    //     & .content {
    //         & .link {
    //             color: var(--clr-primary);
    //         }
    //     }
    // }
}

// .icon-box-shape-animation{
//     position: relative;

//     & .shape {
//         position: absolute;
//         z-index: 1;

//         &-1 {
//             top: -40px;
//             left: -50px;
//             z-index: -1;
//         }
//     }

//     // Responsive
//     @media #{$laptop-device}{
//         & .shape {
    
//             &-1 {
//                 top: -40px;
//                 left: 0px;
//                 z-index: -1;
//             }
//         }
//     }
//     @media #{$desktop-device}{
//         & .shape {
    
//             &-1 {
//                 top: -20px;
//                 left: 0px;
//                 z-index: -1;
//             }
//         }
//     }
//     @media #{$tablet-device}{
//         & .shape {
    
//             &-1 {
//                 top: -20px;
//                 left: 0px;
//                 z-index: -1;
//             }
//         }
//     }
//     @media #{$large-mobile}{
//         & .shape {
    
//             &-1 {
//                 top: -20px;
//                 left: 0px;
//                 z-index: -1;
//             }
//         }
//     }
// }